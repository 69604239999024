import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const ExampleShort = () => (
  <Layout>
    <SEO
      title="How Should I Learn Web Development and Data Science?"
      description="Everything you should think about when making the choice between university, online courses and being self-taught for learning data science and web development"
    />
    <h1>How Should I Learn Web Development and Data Science?</h1>

    <p style={{fontSize:"1.6rem", fontWeight:"600", color:"steelBlue"}}>Everything you should think about when making the choice between university, online courses and being self-taught for learning data science and web development</p>

    

    <p>Choosing how to learn a subject depends on how you are as a person and how much time you have. This site is all about Data Science and Web Development so naturally I will have these things in mind when writing about this. When I finished the article, I realised this advice applies to so many more subjects than just these two. If you are thinking about learning anything and you are torn between university, online courses & being self-taught, you are in the right place.</p>
    
    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/gDHgpPVh.jpg"
        alt="The largest e-commerce companies in the world"
        style={{marginBottom:"5px"}}
      />
    </div>
    <p style={{
        color:"grey", 
        fontSize:"0.9rem", 
        borderTop:"0px", 
    }}
    >Photo credit: <a 
        style={{
          textDecoration:"none",
          color:"grey"
        }}
        target="_blank"
        rel="noopener noreferrer"
        href="https://unsplash.com/@julesbss?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
        Jules Bss
    </a></p>

    <p>Web development is one of the most versatile and rewarding skills a person can learn. There aren't many skills where you can design, build and put something useful into the world that millions of people can have access to. Something similar can be said for data science and if you combine them you will have something special. You finished product could be something as simple as personal CV to the next social media app that changes the world.</p>
    <p>The good news is that you have already decided you want to learn web development/ data science so I don't need to convince you of all the benefits. The next step is figuring out how to get started. Here are the 3 most popular ways to learn web development.</p>
    

    <h2 style={{fontSize:"2rem", color:"steelBlue"}}>University</h2>
    <p>There are a few courses that cover the skills needed for web development and they will have different titles based on where you are. You will need to look up the university that you are thinking of going to and check out their courses & what they teach.</p>
    <h3>Course Structure</h3>
    <p>The benefit of going to university is that it is structured and the course contents have been put together by professionals. The Lecturers will have likely proven themselves in the work environment and are qualified to teach you. Their course material will also have been vetted to make sure it aligns with the quality the university desires. In short, if you do some research on the course that you want to do, you will probably come out with exactly what you went for.</p>
    <p>University courses are structured and you don't need to put any thought into what to study next or what direction to go in. During the course anyway. All you need to do is turn up, listen, study and complete your assignments to the best of your ability. By the end, you will have a well-rounded set of skills.</p>
    <h3>Getting A Job</h3>
    <p>Another benefit of University is that degrees are world recognised and it will be far easier to get a job in your area of study, which is great if getting a job is your goal. Employers are opening up to the idea of hiring people without a degree and looking at their portfolio & experience. But, when the competition is high, a person without a degree will have to do much more to stand out in the crowd.</p>
    <p>Universities also encourage job placements where you can take a year out to go and work for an employer that has strong links with the University. It can be far easier to get a placement in a company as a student and it can be a great introduction to the working world. there is a lot less pressure to perform so you can go all out and far exceed their expectations. it could lead to a job offer once you have completed the course.</p>
    <h3>Personal & Business Connections</h3>
    <p>The final benefit and one that is often overlooked is the connections you can make at university. You can make friends with people on your own course and similar courses that you may work with. You can also make connections with lecturers, some of whom may own their own businesses and have their own connections in the field which you can benefit from.</p>
    <h3>Cost & Time</h3>
    <p>The disadvantages of learning web development at university are the obvious ones. The cost can be huge depending on where you are. It's easy to spend years & years paying of the student loan which can be a lingering thought in the back of your mind for a long time. Some people don't mind debt and others really dislike it so it's up to you to decide if you can handle it or not. There are grants and scholarships so you might be able to take advantage of something like this to lighten the load.</p>
    <p>Another disadvantage is that you are tied up for a few years. Your course might be 3 years long and you turn out to be more motivated than you thought so you learn everything you need to know in the first year. Now you have a choice to make. Do you stick with the course and work on your ideas in your spare time or do you leave the course entirely and get started on your big plan straight away. This is a choice you have to make and it can be a difficult one that will influence the rest of your life.</p>
    <h3>Structure Again...</h3>
    <p>I touched on the positive of a structured course earlier but this can also be a problem. Structure provided by someone else can cause us to be reliant and make us lazy. One of the most important skills in life is being able to find things for yourself. A course that spoon feeds you and gives you all the answers does not encourage an entrepreneurial spirit. It all depends on what type of person you are and want to be.</p>
    <h3>Too Much Information?</h3>
    <p>The last disadvantage I have for university is that you might not use over half of what you have learned. Formal education and the work environment are two very different things. You might find that by the end of your course, you have forgotten nearly everything from the first two years and it can feel like a waste. Don't worry too much though because knowing that you understood a concept that last time you came across it will give you confidence for the next time you might need to revise it.</p>

    <h2 style={{fontSize:"2rem", color:"steelBlue"}}>Online Courses</h2>
    <p>I'm sure you've seen adverts for online courses and you are familiar they exist. They have been around for a long time and they are growing in popularity. More things are moving online and education is one of them. In the past, you had to be brave to go ahead and try one because they were unproven and not recognised. Things have changed.</p>
    <h3>The Cost</h3>
    <p>The first advantage and one that most people have in mind when choosing to do an online course is the price. Online courses are far, far cheaper than formal university courses. You probably won't need to take out a loan and get into any debt to get started with online learning. Some of the best courses also provide free resources.</p> 
    <p>Also, many of the largest software providers offer free introductory/trial periods which give you more than enough time to use while you are studying. The ones that don't do trial periods can offer free tiers to their software that are limited but offer generous features to students.</p>
    <h3>The Distance</h3>
    <p>Another benefit is the distance factor. All you need to take part in an online course is an internet connection and a computer. Some online course providers allow you to download their course material, including videos. It means you can work on them later, even if you don't have an internet connection. If you have a job or lead a busy life, you don't have to spend even more time out of your day travelling to a classroom to be physically present. You can set up in your own chosen location and get to work.</p>
    <p>The downside to distance of course is that there is no personal connection between you and your teacher. You can complete the course without ever having spoken to them. The option is usually there but depending on how active your lecturer is and when the course was published, this can be quite difficult. Imagine taking an online course that was published one year ago. You're into lecture five and have some questions, who do you ask?</p>
    <h3>Communication</h3>
    <p>Most online courses have chat features where you can see previous questions. It's likely that after a year, most of the questions have been asked and have answers. If you have a new question that hasn't been answered, then you can ask it in the forum and hope for an answer. Or get in touch with the course teacher and hope for a response. The best courses usually have great support so even if the original lecturer is nowhere to be found, there will be another supporting officer whose job it is to help students in the forums/ chat.</p>
    <p>Compare this to university and if you had a question at university, you would raise your hand and ask it on the spot or make a note of your question and wait to see the lecturer for a face to face once the lesson is done. This can be a good or bad thing. Online courses force you to try your best to find a solution yourself if you don't want to risk waiting days for a reply.</p>
    <h3>The Structure</h3>
    <p>A benefit that online courses share with universities is the structured learning. A good teacher who has put together an online course will have included modules that cover a wide range of topics. Wide enough to give you a great start for your career and not too wide that you have general information in everything without any depth.</p>
    <h3>Continued Learning</h3>
    <p>One benefit that is often overlooked is continued learning and access to the course. There will eventually come a time when technology and practises will be updated since the course is first launched. The lecturer has a few choices. Leave it as it is and launch a brand new course, meaning if you want to learn new material, you have to spend more money. The better and more generous course providers I have seen, add new sections to their existing courses so you can just go back and learn the new information for free.</p>
    <p>You have lifetime access to most online courses once you have paid for them so once you complete them you can keep going back. Did you forget a concept a few days ago and need a refresh? No problem, just open up the course and give yourself a refresh. Not to mention, you can look at the notes you probably would have taken. At university, looking at notes will most likely be your only option. You might have voice recordings but these might be hard to navigate, or presentation slides you might have saved on your drive. The ability to go back and revisit course material in its entirety is golden.</p>
    <h3>Choose When to Work</h3>
    <p>Another benefit is that you can study in your own time. I mentioned having a job earlier or being a busy individual. If this is the case, choosing when you can study can be a real advantage. Again, this has its positives and negatives. If you are a driven person, you will take the opportunity to learn every day. Including weekends.</p> 
    <p>If the course is exciting, it will be easy to do and you will even look forward to learning. It could also easily be a chore and if you are not used to structure, you might start the course and never finish it. You will have spent money and let it go to waste because you couldn't find the energy to stay committed and keep away from distractions.</p>
    <h3>Finding The Information</h3>
    <p>Many online courses cover a wide range of areas in a topic and it's likely that you will only need the one course to get started. The downside is that you might have to create your own syllabus by choosing multiple courses. This is okay if the course creator has thought ahead and covered multiple areas on multiple courses. This way, you just buy more of their courses. If not, you will have to find the information yourself from somewhere else. The good thing is that a lot of courses know where they are lacking and they will point you in the right direction with course notes and further reading material.</p>
    <h3>Qualifications...</h3>
    <p>A downside to online courses and why people still go to university is that the qualifications are not as recognised as accredited university qualifications. The type of person that takes an online course will generally know the risk of not having a recognised qualification and have a plan in mind before starting. Their plan is more of an unorthodox approach to finding employment or using the skills they have learned. They might not want a job and want to use their skills to build their own project. If they want a job, they might plan on getting in touch with the company owner directly and selling themselves more personally than sending a CV and hoping for the best.</p>

    <h2 style={{fontSize:"2rem", color:"steelBlue"}}>Self-Taught</h2>
    <p>You could argue that online courses can be included in the self-taught section. For self-taught, I will focus more on the free options. Online courses was a whole section in itself.</p>
    <h3>Frustrating & Rewarding</h3>
    <p>Self-taught, while often the most frustrating can also be the most rewarding. People often wear the badge of self-taught more proudly than others with a degree. Those who are self-taught usually get more praise. As long as they manage to prove themselves and create something meaningful. If you are self-taught and don't do anything with the skills, it can feel like a waste of time because you don't have anything to show for it.</p> 
    <p>When you complete a university course, you get a degree. When you complete an online course, you can get a qualification or certificate of some kind. You generally don't "complete" things when you are self-taught. It's an ongoing process of learning, as life should be if you want to progress.</p>
    <h3>Start Straight Away</h3>
    <p>The number one benefit of being self-taught is that you can start working on your project immediately. Most people that are self-taught have something in mind that they want to make and they get started. Everything they do is goal orientated and they have tasks in mind. These goals will be clear but the process of getting there will not be, they have to put work in to figure out a solution.</p> 
    <p>There are usually multiple ways to solve any problem so the first solution might not be the best. They can either make a choice to use the solution they have found and press on or keep looking for other ways to solve the problem. Potentially wasting time but also risking having a suboptimal solution.</p>
    <h3>What a Mess...</h3>
    <p>The path to being self-taught is not a straight line. It can be a frustrating mess and it can be lonely because everyone will take a different path to being proficient in their field. If you ask an Architect or Doctor how they got to where they are, their stories will sound similar. Self-taught people won't have such relatable paths although they will have relatable struggles and rewards.</p>
    <p>When you take a structured course, you will likely build what you are asked to and follow a tutorial. When you have completed the course or when it's time for your final project is when you start thinking about what you can build by yourself. Think about learning how to drive a car. I have always felt that I learned the most and became comfortable on the road after I passed my test. Concepts sink in far faster when you have no one to rely on. This brings me to my next point.</p>
    <h3>Practical Learning</h3>
    <p>Self-taught people often learn so much faster. When you don't have a safety net of teachers or solutions to tutorial problems, you know that making it work is on yourself. Of course, you can go and ask for help but that takes time and close to your last resort. Somewhere near scrapping the project, starting again or finding a new one.</p> 
    <p>As people, we learn the fastest when we make our own mistakes. Ideas make sense because you have seen the problem and know why the solution exists. At university and in formal education in general, we usually get the solution to a problem before we even knew the problem exists. It makes us unable to appreciate the solution as much.</p>
    <h3>Tunnel Vision</h3>
    <p>It's far easier to learn only what you need to know. If you have a project in mind and you are working on it, you will have laser focus on getting it done. It will be hard to get distracted and work on something unnecessary because it isn't going to add to your project in any way. You will gain knowledge along the way and be able to learn about things you didn't know about before you started. Although, they will still be relevant to your project and improve it in some way.</p>
    <h3>Can You Handle it?</h3>
    <p>Being self-taught entirely is not for everyone. For different times in life, you will need some form of self-learning because everything isn't just put in front of us. But, choosing to be self-taught in a complex subject when formal education was an option takes certain characteristics that everyone doesn't have.</p> 
    <p>I can imagine that the failure rate to success goes up as we go down the list, i.e. University has the highest success compared to self-taught being the lowest. I also imagine that the feeling of accomplishment goes the opposite direction.</p>

    <h2 style={{fontSize:"2rem", color:"steelBlue"}}>What Should You Do?</h2>
    <p>You might have noticed that while these are 3 different methods for learning data science, you will likely use a mixture of two or more to reach your goal.</p>
    <p>It's important to note what your goal is before you get started. If your goal is to get a job, it will be far easier to enter formal education at university and get a degree. Without a degree you will need a portfolio of great examples of your work and be prepared to work a bit harder that a graduate.</p> 
    <p>Alternatively, if you want to build your own website or project and have complete control, it might be better to do an online course or learn as you go by googling your problems.</p>


    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default ExampleShort
